@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

.custom-label {
    display: inline-block; /* Or any other styling */
    margin-right: 10px;
    padding: 5px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
}